.box.settings-integrations {
  height: fit-content;
}

.box.settings-integrations:first-child {
  margin-top: 0;
}

.settings-integrations__token {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.settings-integrations__token > span {
  color: var(--text-muted);
  margin-top: 0.5rem;
}
.settings-integrations__token > span.ellipsis {
  color: var(--pixelme-color);
  border: var(--input-border);
  border-radius: 0.2rem;
  max-width: 400px;
  padding: 5px;
}

.settings-integrations .buffer__header {
  display: flex;
  align-items: center;
}

.buffer__header > svg {
  margin-right: 5px;
}

.buffer__header > a {
  margin-left: 0.5rem;
}

.buffer .settings-box__content {
  margin-top: 0.5rem;
}
