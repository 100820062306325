.settings-pixels > .box {
  margin-top: 1rem;
}
.settings-pixels > .box:first-child {
  margin-top: 0;
}

.settings-pixels__add form {
  display: flex;
  margin: 0.5rem 0;
}

.settings-pixels__add form > * {
  margin-right: 1rem;
}

#new-pixel input {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 0.5rem;
}

#new-pixel button {
  height: 1.8rem;
  line-height: 1.8rem;
  padding: 0 1rem;
}

#new-pixel .drop-down__content--scrollable--true {
  max-height: 350px;
  margin-bottom: 12px;
}
#new-pixel .icon.pixel {
  margin-right: 5px;
}

.settings-pixels__add form > *:last-child {
  margin-right: 0;
}

input#pixelName {
  min-width: 180px;
}

input#pixelId {
  min-width: 236px;
}
.settings-pixels__list__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.settings-pixels__list__title .button {
  height: 1.8rem;
  font-size: var(--small-size);
  line-height: 1.8rem;
  padding: 0 0.5rem;
}

.settings-pixels__list__title span {
  font-size: var(--small-size);
  color: var(--text-muted);
  margin-left: 1rem;
}

.settings-pixels__list__headers {
  color: var(--text-muted);
}

.settings-pixels__list__line {
  display: grid;
  grid-template-columns: repeat(3, 1fr) 3rem;
  grid-gap: 0 1rem;
  height: 1.8rem;
  align-items: center;
}

.settings-pixels__list__line .input {
  padding: 0.2rem;
}

.settings-pixels__list__line--odd {
  background: #f9f9f9;
}
.settings-pixels__list--del {
  justify-self: end;
}

.settings-pixels__custom form {
  display: flex;
  flex-direction: column;
}

.settings-pixels__custom form > * {
  margin-top: 1rem;
}

.settings-pixels__custom form textarea {
  height: 5rem;
}

.settings-pixels__custom .input-label {
  color: var(--text-muted);
}
.settings-pixels__list--ad {
  display: flex;
  align-items: center;
}
.settings-pixels__list--ad .pixel {
  margin-right: 5px;
}
