.buffer-profile {
  display: grid;
  grid-template-columns: 300px 300px;
  padding: 1rem 0;
  grid-gap: 1rem;
  border-bottom: var(--separator);
}

.buffer-profile:first-child {
  padding-top: 0;
}

.buffer-profile:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.buffer-profile__title {
  display: flex;
  align-items: center;
}

.buffer-profile__title > * {
  margin-right: 0.5rem;
}
.buffer-profile__title > *:last-child {
  margin-right: 0;
}

.buffer-profile__avatar {
  background: center #eee;
  background-size: 100%;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
}

.buffer-profile__username {
  color: var(--pixelme-color);
}

.buffer-profile svg > rect {
  fill: var(--bg-color);
}

.buffer-profile__active {
  display: flex;
  align-items: center;
}
.buffer-profile__active {
  color: var(--text-muted);
  cursor: pointer;
}
.buffer-profile__active .switch-container {
  margin-right: 0.5rem;
}

.buffer-profile .button {
  width: 100px;
}

.buffer-profile .dropdowns__header {
  margin-bottom: 0.5rem;
}

.buffer-profile .drop-down__container {
  position: relative;
}
