.bar {
  background: var(--bar-color);
  color: white;
  height: 100%;
  width: 100%;
}

.bar > ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

.bar__menu-item {
  width: 100%;
  display: flex;
  margin: 1.5rem auto 0 auto;
}

svg.bar__icon {
  fill: none;
}

/* icons */
svg.bar__icon .icon-background {
  fill: var(--bar-color);
}

svg.bar__icon .icon-main-fill {
  fill: var(--text-muted);
}

svg.bar__icon .icon-main-stroke {
  stroke: var(--text-muted);
}

svg.bar__icon .icon-back-fill {
  fill: var(--bar-color);
}

svg.bar__icon .icon-back-stroke {
  stroke: var(--bar-color);
}

/* icons hover */
svg.bar__icon:hover {
  cursor: pointer;
}

svg.bar__icon:hover .icon-main-fill {
  fill: var(--white);
}

svg.bar__icon:hover .icon-main-stroke {
  stroke: var(--white);
}

/* icons selected */
a.current > .bar__menu-item svg.bar__icon .icon-background {
  fill: var(--pixelme-color);
}

a.current > .bar__menu-item svg.bar__icon .icon-main-fill {
  fill: var(--white);
}

a.current > .bar__menu-item svg.bar__icon .icon-main-stroke {
  stroke: var(--white);
}

a.current > .bar__menu-item svg.bar__icon .icon-back-fill {
  fill: var(--pixelme-color);
}

a.current > .bar__menu-item svg.bar__icon .icon-back-stroke {
  stroke: var(--pixelme-color);
}
